import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../slices/user'
import commonReducer from '../slices/common'
import gstrReducer from '../slices/forms/gstr1'
import refundReducer from '../slices/forms/refund'
import staffReducer from '../slices/staff'

export default configureStore({
    reducer: {
        user: userReducer,
        common: commonReducer,
        gstr: gstrReducer,
        refund: refundReducer,
        staff: staffReducer,
    },
})