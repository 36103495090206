import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }else{
            delete config.headers.Authorization;
        }
        // Make sure not to set 'Content-Type' if sending FormData
        if (config.data instanceof FormData) {
            config.headers['Content-Type'] = 'multipart/form-data';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

let retryToken = false;
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry && !retryToken) {
            originalRequest._retry = true;
            retryToken = true;
            try {
                // Get the refresh token
                const newToken = await refreshAccessToken();

                // Update the Authorization header
                axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
                originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

                // Retry the original request
                return axiosInstance(originalRequest);
            } catch (err) {
                // Handle token refresh failure (e.g., log out the user)
                console.error('Token refresh failed:', err);
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                // Redirect to login page or show an error message
            }
        }
        return Promise.reject(error);
    }
);

const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken')
    try {
        localStorage.removeItem('accessToken');
      console.log(process.env.REACT_APP_REFRESH_TOKEN_URL);
      const response  = await axiosInstance.post(process.env.REACT_APP_REFRESH_TOKEN_URL, {refresh: refreshToken});
      const { token, refresh } = response.data.data;
      localStorage.setItem("accessToken", token)
      localStorage.setItem("refreshToken", refresh)
      return token
  
    } catch (error) {
      console.log(error)
      throw error
    }
  
  };

export default axiosInstance;
