import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Image, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { LiaUserTieSolid } from 'react-icons/lia';
import forms_logo from '../assets/images/forms.svg';
import activities_logo from '../assets/images/activities.svg';
import staff_logo from '../assets/images/staff.svg';
import profile_logo from '../assets/images/profile.svg';
import EUR from '../assets/images/eu.svg';
import USD from '../assets/images/us.svg';
import AU from '../assets/images/au.svg';
import UserBase from './common/Base';
import axiosInstance from '../services/instance';
import { useSelector } from 'react-redux';

const Dashboard = () => {
  const {userData} = useSelector((state) => state.user);
  const [notifications, setNotifications] = useState([]);
  const [forex, setForex] = useState([]);
  const dashboardCards = [
    { href: '/gst-forms', imgSrc: forms_logo, title: 'Manage Form', itemNo: '3' },
    { href: '/manage-staff', imgSrc: staff_logo, title: 'Manage Staff', itemNo: '4' },
    { href: '#', imgSrc: activities_logo, title: 'Activities', itemNo: '5' },
    { href: '#', imgSrc: profile_logo, title: 'Manage Profile', itemNo: '6' }
  ];
  const getNotifications = () => {
    const response = [
      {
        title: 'New GSTR Forms Uploaded',
        link: '#',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        date: '01-09-2024'
      },
      {
        title: 'Forms waiting for approval',
        link: '#',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        date: '01-09-2024'
      },
      {
        title: 'New GSTR Forms Uploaded',
        link: '#',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        date: '01-09-2024'
      },
      {
        title: 'Forms waiting for approval',
        link: '#',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        date: '01-09-2024'
      }
    ];
    setNotifications(response);
  };

  const flagImages = {
    AU: AU,
    EUR: EUR,
    USD: USD
    // Add more flags as needed
  };

  const getForex = async () => {
    try {
      const response = await axiosInstance.post(`/api/v1/forex/latest-forex`, {
        page: 1,
        perpage: 25
      });
      setForex(response.data.data.list);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  useEffect(() => {
    getNotifications();
    getForex();
  }, []);

  return (
    <UserBase>
      <Container fluid>
        <Row>
          <Col lg={4}>
            {/* Welcome Section */}
            <div className="pa-bento-box pa-bento-box-1 mb-3">
              <h3 className="pa-welcome-notifi">
                <span className="!inline-flex items-center justify-center">
                  <LiaUserTieSolid />
                </span>{' '}
                Welcome {userData?.name && userData?.name.trim() != '' && userData.name}
              </h3>
            </div>

            {/* Forex Section */}
            <Card className="pa-bento-box pa-bento-box-2">
              <div className="header-title">
                <h4>Live Forex Rates</h4>
              </div>
              <div className="pa-forex-list">
                {forex.map((item, index) => (
                  <a key={index + 1} className="d-flex justify-content-between">
                    <div className="d-flex">
                      <img
                        className="pa-cforex-flag"
                        src={item.currency.url}
                        alt={item.countryCode}
                      />
                      <div>
                        <p>{item.currency.name}</p>
                      </div>
                    </div>
                    <div className="text-right">
                      <h3>{`${item.currency.symbol} ${item.amount}`}</h3>
                    </div>
                  </a>
                ))}
              </div>
            </Card>
          </Col>

          <Col lg={4}>
            <Row>
              {[0, 1].map((groupIndex) => (
                <Col lg={6} className="pl-0" key={groupIndex}>
                  {dashboardCards
                    .filter((_, index) => Math.floor(index / 2) === groupIndex)
                    .map((item, i) => (
                      <Card key={i+1} className={`pa-bento-box pa-bento-box-${item.itemNo} mb-3 text-center mh-bentobox`}>
                          <Nav.Link as={NavLink} to={item.href}>
                              <div className="mb-2 iq-thumb rounded-full flex items-center justify-center mx-auto">
                              <Image src={item.imgSrc} className="img-fluid" alt={item.title} />
                              <div className="iq-image-overlay"></div>
                              </div>
                              <h6>{item.title}</h6>
                          </Nav.Link>
                      </Card>
                    ))}
                </Col>
              ))}
            </Row>
          </Col>
          <Col lg={4} className="pl-0">
            <div className="pa-bento-box pa-bento-box-7 mb-3">
              <div id="pa-notification">
                <div className="header-title">
                  <h4>Notification</h4>
                </div>
              </div>

              <div className="profile-details pt-3">
                {notifications.map((notification, index) => (
                  <div key={index} className="media align-items-center mb-3">
                    <div className="media-body">
                      <div className="media justify-content-between">
                        <div>
                          <h6 className="mb-0">
                            <a href={notification.link}>{notification.title}</a>
                          </h6>
                          <p className="mb-0 pa-p-d-small">{notification.content}</p>
                          <p className="mb-0 pa-p-d-small italic">{notification.date}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </UserBase>
  );
};

export default Dashboard;
