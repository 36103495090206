import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import Logo from '../assets/images/logo.png'
import axiosInstance from '../services/instance';
import { SIGN_IN } from '../constants/services';
import { setUserData, setAuthenticatedStatus } from '../slices/user'
import { useDispatch } from 'react-redux';

function Login() {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const result = await axiosInstance.post(SIGN_IN, { username, password })
            const { data: userData } = result;
            const { data: user, refresh, token } = userData;
            const { email, id, name, phone, permission_code, role } = user
            localStorage.setItem('accessToken', token)
            localStorage.setItem('refreshToken', refresh)
            dispatch(setUserData({ email, id, name, mobile:phone, permission_code, role: role?.name }))
            dispatch(setAuthenticatedStatus(true))

        } catch (e) {
            toast('Invalid Username or Password', {
                position: "top-center", hideProgressBar: true, style: {
                    color: 'red'
                }
            })
        }

    }

    return (
        <div className="wrapper">
            <section className="login-content">
                <Container className="h-100">
                    <Row className="d-flex justify-content-center align-items-center height-self-center h-100">
                        <Col md={5} sm={12} className="align-self-center">

                            <div className="sign-user_card pf-sign-user_card pf-shadow">
                                <img
                                    src={Logo}
                                    className="img-fluid rounded-normal light-logo logo"
                                    alt="logo"
                                />
                                <h3 className="mb-3">Sign In</h3>
                                <p>Login to stay connected.</p>
                                <ToastContainer />
                                <Form>
                                    <Row>
                                        <Col lg={12}>
                                            <Form.Group className="floating-label">
                                                <Form.Control
                                                    type="text"
                                                    placeholder=" "
                                                    className="floating-input"
                                                    value={username}
                                                    onChange={e => setUsername(e.target.value)}
                                                />
                                                <Form.Label>Email</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12}>
                                            <Form.Group className="floating-label">
                                                <Form.Control
                                                    type="password"
                                                    placeholder=" "
                                                    className="floating-input"
                                                    value={password}
                                                    onChange={e => setPassword(e.target.value)}
                                                />
                                                <Form.Label>Password</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3 text-left">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Remember Me"
                                                    id="customCheck1"
                                                    className="custom-control-input"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <a href="auth-recoverpw.html" className="text-primary float-right">
                                                Forgot Password?
                                            </a>
                                        </Col>
                                    </Row>
                                    <Button className="btn btn-primary" type="submit" onClick={handleSubmit}>
                                        Sign In
                                    </Button>
                                    <div className="pf-sign-or">
                                        <span>Or</span>
                                    </div>
                                    <Button variant="outline-primary" className="login-with-google-btn">
                                        Sign in with Google
                                    </Button>
                                    <p className="mt-3">
                                        Create an Account <a href="sign-up.html" className="text-primary">Sign Up</a>
                                    </p>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
}

export default Login;
