import { Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { format } from 'date-fns'
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedMonthYear } from "../../../slices/common";
import { useYearMonth } from "../../YearMonth";





const GstrHeader = ({headerTitle, type}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [selectedMonth, setMonth] = useState(new Date())
    const [selectedYear, setYear] = useState(new Date)
    const {setSelectedMonth, setSelectedYear} = useYearMonth()
    const formatMonth = (date) => {
        return date ? format(date, 'MMMM') : '';
    };
    const formatYear = (date) => {
        return date ? format(date, 'yyyy') : '';
    };
    
    useEffect(() => {
        console.log('Loading')
        const params = new URLSearchParams(location.search);
        const monthParam = params.get("month");
        const yearParam = params.get("year");
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        if (!monthParam || !yearParam) {
            if (!monthParam) {
                params.set("month", currentMonth);
            }
            if (!yearParam) {
                params.set("year", currentYear);
            }

            navigate({
                pathname: location.pathname,
                search: `?${params.toString()}`
            }, { replace: true });
        }

        const inputMonth = parseInt(params.get('month')) || currentMonth;
        const inputYear = parseInt(params.get('year')) || currentYear;
        const monthDate = new Date(currentYear, inputMonth - 1); // Month is 0-indexed
        const yearDate = new Date(inputYear, currentMonth - 1); // Day and month are adjusted

        setMonth(monthDate);
        setYear(yearDate);
        //set values to datepicker
        console.log('set values to datepicker');
        dispatch(setSelectedMonthYear({ month: inputMonth, year: inputYear }))
    }, [location.search]);

    const handleSubmit = () => {
        const params = new URLSearchParams(location.search);
        const monthMap = {
            January: 1,
            February: 2,
            March: 3,
            April: 4,
            May: 5,
            June: 6,
            July: 7,
            August: 8,
            September: 9,
            October: 10,
            November: 11,
            December: 12,
        };

        const monthNumber = monthMap[formatMonth(selectedMonth)]; // Convert month name to number
        const yearNumber = parseInt(formatYear(selectedYear), 10);

        setSelectedMonth(monthNumber);
        params.set("month", monthNumber);
        params.set("year", yearNumber);
        navigate({
            pathname: location.pathname,
            search: `?${params.toString()}`
        }, { replace: true });
        dispatch(setSelectedMonthYear({ month: monthNumber, year: yearNumber }));

    }

    return (<Row>
        <Col lg={2}>
            <h3>{headerTitle ? headerTitle : 'GSTR-1'}</h3>
        </Col>
        {type === 'gstr' && 
        <Col lg={10}>
            <div className="pa-choose-date">
                <Row>
                    <Col lg={2} >
                        <Form.Label>
                            Choose Year:
                        </Form.Label>

                    </Col>
                    <Col lg={3} className="date datepicker datepicker1">

                        <DatePicker
                            selected={selectedYear}
                            onChange={(date) => setYear(date)}
                            dateFormat="MMMM"  // Format to show only month and year
                            showYearPicker  // Show only month and year picker
                            // Use Bootstrap styling
                            customInput={
                                <InputGroup>
                                    <InputGroup.Text ><i className="las la-calendar"></i></InputGroup.Text>
                                    <Form.Control
                                        value={formatYear(selectedYear)}
                                    />
                                </InputGroup>
                            }
                        />

                    </Col>
                    <Col lg={2} >
                        <Form.Label>
                            Choose Month:
                        </Form.Label>

                    </Col>
                    <Col lg={4} className="date datepicker datepicker1">

                        <DatePicker
                            selected={selectedMonth}
                            onChange={(date) => setMonth(date)}
                            dateFormat="yyyy"  // Format to show only month and year
                            showMonthYearPicker  // Show only month and year picker
                            // Use Bootstrap styling
                            customInput={
                                <InputGroup>
                                    <InputGroup.Text ><i className="las la-calendar"></i></InputGroup.Text>
                                    <Form.Control
                                        value={formatMonth(selectedMonth)}
                                    />
                                </InputGroup>
                            }
                        />
                    </Col>
                    <Col lg={1}>
                        <Button onClick={handleSubmit}>Submit</Button>
                    </Col>
                </Row>
            </div>
        </Col>
        }
    </Row>)
}

export default GstrHeader;