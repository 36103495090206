import { Navbar, Nav, Row, Col, Button, Overlay, Tooltip, Card, Dropdown, Badge, NavDropdown, Form, FormControl } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import Logo from '../../assets/images/logo.png';
import LogoWhite from '../../assets/images/logo-white.png';
import GstrHeader from "../forms/headers/GstrHeader";
import MainHeader from "./MainHeader";
import { setNavOpen } from "../../slices/common";
import { clearUserData } from "../../slices/user";
import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ResetPasswordModal from "./ResetPasswordModal";

const Header = (props) => {
    const { showLogo, type, headerTitle } = props;
    const [show, setShow] = useState(false);
    const target = useRef(null); // Ref to target the button for Overlay
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const navOpen = useSelector((state) => state.common.navOpen);
    const {userData} = useSelector((state) => state.user);
    const [showProfileDropdown, setShowProfileDropdown] = useState(false);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

    // Toggle dropdown visibility
    const handleProfileToggle = (isOpen) => {
        setShowProfileDropdown(isOpen);
    };
    useEffect(() => {
        if (!showLogo) setShow(false);
    }, [showLogo])


    const handleSignOut = () => {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        dispatch(clearUserData())
        navigate('/login')
    }

    const toggleSidebar = () => {
        document.body.classList.toggle('sidebar-main');
    }

    const handleResetPasswordModalClose = async () => {
        setShowResetPasswordModal(false);
      }

    return (
        <>
        <div className="iq-top-navbar">
            <div className="iq-navbar-custom">
            <Navbar expand="lg" className="navbar navbar-light p-0">
                <div className="iq-navbar-logo d-flex align-items-center justify-content-between">
                    <i className="ri-menu-line wrapper-menu" onClick={toggleSidebar}></i>
                    <Nav.Link as={NavLink} to="/" className={`header-logo`}>
                        <img src={Logo} className="img-fluid rounded-normal light-logo" alt="logo" />
                        <img src={LogoWhite} className="img-fluid rounded-normal darkmode-logo d-none" alt="logo" />
                    </Nav.Link>
                </div>
                <div md={showLogo || navOpen ? 11 : 8} className="iq-search-bar device-search" >
                    {type === 'gstr' || type === 'refunds' ? <GstrHeader headerTitle={headerTitle} type={type} /> : <MainHeader headerTitle={headerTitle} />}
                </div>
                <Navbar expand="lg" className="d-flex align-items-center !static p-0">
                    {/* Navbar Toggler */}
                    <Navbar.Toggle aria-controls="navbarSupportedContent">
                    <i className="ri-menu-3-line"></i>
                    </Navbar.Toggle>

                    {/* Navbar Collapse */}
                    <Navbar.Collapse id="navbarSupportedContent">
                    <Nav as="ul" className="ml-auto align-items-center navbar-list">
                        {/* Search */}
                        <Nav.Item as="li" className="nav-icon nav-item search-content">
                        <Dropdown as="div" className="!static">
                            <Dropdown.Toggle as="a" className="search-toggle rounded text-[22px] px-3" id="dropdownSearch">
                            <i className="ri-search-line"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="iq-search-bar iq-sub-dropdown p-2">
                            <Form className="searchbox">
                                <Form.Group className="position-relative mb-0">
                                <FormControl type="text" placeholder="type here to search..." className="search-input font-size-12" />
                                <a className="search-link p-0">
                                    <i className="las la-search"></i>
                                </a>
                                </Form.Group>
                            </Form>
                            </Dropdown.Menu>
                        </Dropdown>
                        </Nav.Item>

                        {/* Help */}
                        <NavDropdown as="li" title={<i className="ri-question-line"></i>} id="dropdownMenuButton01" className="nav-icon nav-item ">
                        <NavDropdown.Item href="#">
                            <i className="ri-questionnaire-line"></i> Help
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#">
                            <i className="ri-recycle-line"></i> Training
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#">
                            <i className="ri-refresh-line"></i> Updates
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#">
                            <i className="ri-service-line"></i> Terms and Policy
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#">
                            <i className="ri-feedback-line"></i> Send Feedback
                        </NavDropdown.Item>
                        </NavDropdown>

                        {/* Settings */}
                        <NavDropdown as="li" title={<i className="ri-settings-3-line"></i>} id="dropdownMenuButton02" className="nav-icon nav-item ">
                        <NavDropdown.Item href="#">
                            <i className="ri-settings-3-line"></i> Settings
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#">
                            <i className="ri-hard-drive-line"></i> Get Drive for desktop
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#">
                            <i className="ri-keyboard-line"></i> Keyboard Shortcuts
                        </NavDropdown.Item>
                        </NavDropdown>

                        {/* Profile */}
                        <Dropdown as="li" className="nav-icon nav-item caption-content" show={showProfileDropdown} onToggle={handleProfileToggle}>
                        
                            <Dropdown.Toggle as="a" className="search-toggle">
                                <div className="caption bg-primary line-height cursor-pointer">{userData?.name && userData?.name.trim() != '' ? userData.name[0] : 'P'}</div>
                            </Dropdown.Toggle>

                        
                            <Dropdown.Menu className="dropdown-menu iq-sub-dropdown" align="end">
                            
                                <Card className="mb-0">
                                    <Card.Header className="d-flex justify-content-between align-items-center mb-0">
                                        <div className="header-title">
                                            <h4 className="card-title mb-0">Profile</h4>
                                        </div>
                                        <Badge pill bg="primary" className="cursor-pointer close-data text-right" onClick={() => setShowProfileDropdown(false)}>
                                            <i className="ri-close-fill"></i>
                                        </Badge>
                                    </Card.Header>

                                
                                    <Card.Body>
                                    
                                        <div className="profile-header">
                                            <div className="cover-container text-center">
                                                <div className="rounded-circle profile-icon bg-primary mx-auto d-block">
                                                    {userData?.name && userData?.name.trim() != '' ? userData.name[0] : 'P'}
                                                </div>
                                                <div className="profile-detail mt-3">
                                                    <h5><Link to="/manage-profile">{userData?.name && userData?.name.trim() != '' ? userData.name : 'Noname'}</Link></h5>
                                                    <p>{userData?.email && userData?.email.trim() != '' && userData.email}</p>
                                                </div>
                                                <div className="mt-3">
                                                    <Button variant="link" onClick={() => setShowResetPasswordModal(true)}>Update Password</Button>
                                                </div>
                                                <Button onClick={handleSignOut}>Sign Out</Button>

                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Navbar>
            </div>
        </div>
        <ResetPasswordModal showModal={showResetPasswordModal} handleModalClose={handleResetPasswordModalClose} />
        </>
    );
};

export default Header;
